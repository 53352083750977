import React from 'react'
import SearchSection from '../sections/OtherPages/SearchSection'
import Footer from '../sections/Home/Footer'

const SearchPage = () => {
  return (
    <div>
      <SearchSection/>
      <Footer/>
      
    </div>
  )
}

export default SearchPage
