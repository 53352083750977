import React from 'react'
import NavBarLoggedIn from '../components/NavBarLoggedIn'
import Footer from '../sections/Home/Footer'
import Profile from '../sections/Home/Profile'

const ProfilePage = () => {
  return (
    <div className=' pt-20'>
      
    <Profile/>




      <Footer/>


    </div>
  )
}

export default ProfilePage
